import { inject } from '@angular/core';
import { HttpRequest, HttpErrorResponse, HttpInterceptorFn, HttpHandlerFn } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

export function generateErrorMessage(request: HttpRequest<any>) {
  const correlationid = request.headers.get('X-Correlation-ID');
  let errMsg = `Correlation ID: ${correlationid} `;
  return errMsg;
}

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const toastr = inject(ToastrService);
  const exceptionUrls = ['/signupNewCustomer'];
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMsg = '';
      if (!exceptionUrls.some((url) => req.url.includes(url))) {
        if (error.error instanceof ErrorEvent) {
          errorMsg = `Client Error: ${error.error.message}`;
          toastr.error(generateErrorMessage(req), errorMsg);
        } else {
          errorMsg = `Server Error Code: ${error.status}, Message: ${error.message}`;
          toastr.error(generateErrorMessage(req), errorMsg);
        }
      } else {
        errorMsg = error as any;
      }
      console.log(errorMsg);
      return throwError(() => errorMsg);
    }),
  );
};
