import { ApplicationRef, Injectable } from '@angular/core';
import { first, interval, concat, filter, of, tap } from 'rxjs';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CheckforUpdateService {
  env = environment;
  // Reference: https://angular.io/guide/service-worker-communications
  constructor(public updates: SwUpdate) {}

  checkVersionUpdate() {
    const everySixHours$ = interval(6 * 60 * 60 * 1000);

    const everySixHoursOnceAppIsStable$ = concat(of(true), everySixHours$);

    everySixHoursOnceAppIsStable$.pipe(first()).subscribe(async () => {
      try {
        const updateFound = await this.updates.checkForUpdate();
        console.log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
        if (updateFound) {
          console.log('new version detected, refreshing the app');
          document.location.reload();
        }
      } catch (err) {
        console.warn('Failed to check for updates:', err);
      }
    });
  }
}
