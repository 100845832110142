import { HttpHandlerFn, HttpHeaders, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Guid } from 'guid-typescript';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthSsoService } from '../services/auth/auth-sso.service';

export const authInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next: HttpHandlerFn) => {
  const clientName = 'cs_chargingservices_frontend';
  const authService = inject(AuthSsoService);
  const activatedRoute = inject(ActivatedRoute);

  function refreshTokenIfNotExpired(): Observable<boolean> {
    const promise = new Promise<boolean>((resolve) => {
      const authenticated = authService.isLoggedIn();
      const expired = authService.isKeycloakTokenExpired();

      if (authenticated && expired) {
        authService.refreshToken().then(() => {
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });

    const observable = from(promise);
    return observable;
  }

  function addHeader(request: HttpRequest<any>, queryParams: any): HttpRequest<any> {
    const token = authService.fdGwToken;
    let headers = new HttpHeaders();
    headers = headers.set('authorization', `Bearer ${token}`);
    headers = headers.set('x-client', clientName);
    headers = headers.set('X-Correlation-ID', Guid.create().toString());
    // headers = headers.set('X-Demo-Mode', 'true');
    if (queryParams['mode'] === 'demo') {
      headers = headers.set('X-Demo-Mode', 'true');
    }
    return request.clone({ headers });
  }

  return refreshTokenIfNotExpired().pipe(
    switchMap(() => activatedRoute.queryParams),
    switchMap((queryParams) => next(addHeader(req, queryParams))),
  );
};
