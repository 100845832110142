import { Routes } from '@angular/router';
import { AuthSsoGuard } from '@core/guards/auth-sso.guard';
import { DepartureSchedulingGuard } from '@core/guards/departure-scheduling.guard';
import { FeatureToggleGuard } from '@core/guards/feature-toggle.guard';
import { FunctionalPermissionGuard } from '@core/guards/functional-permission.guard';
import { OnboardingGuard } from '@core/guards/onboarding.guard';
import { UserPermissionGuard } from '@core/guards/user-permission.guard';

const appRoutes = [
  {
    path: 'my-electric-vehicles',
    canActivate: [AuthSsoGuard, FunctionalPermissionGuard, UserPermissionGuard],
    data: {
      requiredFeautureToggle: [],
      requiredFunctionalPermission: ['ViewChargingDashboard'],
      requiredUserPermissions: ['ViewChargingDashboard'],
      redirectUrl: '/overview',
    },
    pathMatch: 'prefix',
    loadChildren: () => import('@pages/my-electric-vehicles/my-electric-vehicles-routes'),
  },
  {
    path: 'charging-management',
    pathMatch: 'prefix',
    canActivate: [AuthSsoGuard, FeatureToggleGuard, OnboardingGuard, UserPermissionGuard, FunctionalPermissionGuard],
    data: {
      requiredFeautureToggle: ['ViewPublicCharging'],
      requiredFunctionalPermission: [],
      requiredUserPermissions: ['ViewPublicCharging'],
      redirectUrl: '/notallowed',
    },
    loadChildren: () => import('@pages/charging-management/charging-management-routes'),
  },
  {
    path: 'onboarding',
    canActivate: [AuthSsoGuard, FeatureToggleGuard, OnboardingGuard],
    data: {
      requiredFeautureToggle: ['ViewPublicCharging'],
      onboardingPageAccessibleToOnboardedCustomer: false,
      requiredFunctionalPermission: [],
      requiredUserPermissions: ['ViewPublicCharging'],
      redirectURL: '/charging-management',
    },
    pathMatch: 'prefix',
    loadChildren: () => import('@pages/charging-management/onboarding/onboardingRoutes'),
  },

  {
    path: 'departure-scheduling',
    canActivate: [
      AuthSsoGuard,
      DepartureSchedulingGuard,
      FeatureToggleGuard,
      FunctionalPermissionGuard,
      UserPermissionGuard,
    ],
    data: {
      requiredFeautureToggle: ['ViewDepartureScheduling'],
      requiredFunctionalPermission: [],
      requiredUserPermissions: ['ViewDepartureScheduling'],
      redirectUrl: '/notallowed',
    },
    pathMatch: 'full',
    loadComponent: () =>
      import('@pages/departure-scheduling/components/departure-scheduling/departure-scheduling.component'),
  },
  {
    path: 'settings',
    canActivate: [AuthSsoGuard, FeatureToggleGuard, FunctionalPermissionGuard, UserPermissionGuard],
    data: {
      requiredFeautureToggle: ['ViewChargingServices'],
      requiredFunctionalPermission: ['ViewChargingDashboard'],
      requiredUserPermissions: ['ViewChargingSettings'],
      redirectUrl: '/notallowed',
    },
    pathMatch: 'full',
    loadComponent: () => import('@pages/charging-settings/components/charging-settings/charging-settings.component'),
  },
  {
    // until charging session route
    path: 'charging-sessions',
    canActivate: [AuthSsoGuard, FeatureToggleGuard],
    pathMatch: 'prefix',
    data: {
      requiredFeautureToggle: ['RTMChargingSession'],
      redirectUrl: '/notallowed',
    },
    loadChildren: () => import('@pages/real-time-monitoring-charging-sessions/rtm-charging-sessions-routes'),
  },
  {
    path: 'admin',
    canActivate: [AuthSsoGuard, FeatureToggleGuard],
    data: {
      requiredFeautureToggle: ['Admin'],
      redirectUrl: '/notallowed',
    },
    pathMatch: 'full',
    loadComponent: () => import('@pages/admin/admin.component'),
  },
  {
    path: 'map',
    canActivate: [AuthSsoGuard, FeatureToggleGuard],
    data: {
      requiredFeautureToggle: ['ViewMap'],
      redirectUrl: '/notallowed',
    },
    pathMatch: 'full',
    loadComponent: () => import('@pages/map/map-root-page.component'),
  },
  {
    path: 'overview',
    canActivate: [AuthSsoGuard],
    loadComponent: () => import('@shared/components/overview/overview.component'),
  },
  {
    path: 'notallowed',
    canActivate: [AuthSsoGuard],
    loadComponent: () => import('@shared/components/not-allowed-page/not-allowed-page.component'),
  },
  {
    path: '',
    redirectTo: 'my-electric-vehicles',
    pathMatch: 'prefix',
  },
  {
    path: '*',
    canActivate: [AuthSsoGuard],
    loadComponent: () => import('@shared/components/overview/overview.component'),
  },
  {
    path: '**',
    redirectTo: 'pageNotFound',
  },
  {
    path: 'pageNotFound',
    canActivate: [AuthSsoGuard],
    loadComponent: () => import('@shared/components/page-not-found/page-not-found.component'),
  },
] satisfies Routes;

export default appRoutes;
