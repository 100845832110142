import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@Injectable()
export class AppCustomTranslateParser extends TranslateDefaultParser {
  // Basically we are copy-pasting logic from  @myscania-translation-utils-angular library
  // we dont want this library to be our dependency, because it is not friendly with stand-alone components now.
  // usign ngx-translate is enough for localization (as myscania uses the sam lib under the hood)
  // but to handle  parameter in localization hub logic below required.
  // reference https://gitlab.scania.com/my-scania-shared/myscania-shared-localization/myscania-translation-utils-angular/-/blob/main/projects/translation-utils/src/lib/utils/custom-translate-parser.ts?ref_type=heads

  override templateMatcher: RegExp = /{\s?([^{}\s]*)\s?}/g;

  override getValue(target: any, key: string): any {
    if (typeof target === 'string' || typeof target === 'number') {
      return target;
    }
    if (Array.isArray(target)) {
      const index = parseInt(key, 10) - 1;
      return target.length > index ? target[index] : undefined;
    }
    return super.getValue(target, key);
  }
}
