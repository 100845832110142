/*!
 * Scope package is built with Stencil
 * Copyright - (c) Scania
 * Team: KYTF.
 */
import { b as bootstrapLazy } from './index-d53b2378.js';
export { s as setNonce } from './index-d53b2378.js';
import { g as globalScripts } from './app-globals-93e19e10.js';
const defineCustomElements = async (win, options) => {
  if (typeof window === 'undefined') return undefined;
  await globalScripts();
  return bootstrapLazy([["sss-navbar-top-menu", [[1, "sss-navbar-top-menu", {
    "mobileMenuExpanded": [32]
  }, [[4, "mobileMenuOpen", "handleMobileMenuOpen"], [4, "mobileMenuClosed", "handleMobileMenuClosed"], [10, "submenuDropOpened", "handleSubmenuOpened"]]]]], ["navbar-header-button", [[1, "navbar-header-button", {
    "icon": [1],
    "hasAlert": [4, "has-alert"],
    "label": [1],
    "disabled": [4],
    "menuOpen": [32],
    "showAlertIcon": [32]
  }, [[2, "click", "handleClick"], [6, "closeDropdowns", "handleClose"], [3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"]]]]], ["navbar-header-notifications", [[1, "navbar-header-notifications", {
    "label": [1],
    "heading": [1],
    "disabled": [32],
    "showAlertIcon": [32]
  }, [[2, "click", "handleClick"], [10, "newNotifications", "addIconAlert"], [10, "removeIconAlert", "removeIconAlert"], [10, "setDisabled", "setDisabled"], [10, "setEnabled", "setEnabled"], [3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"]]]]], ["notifications-container", [[0, "notifications-container", {
    "environment": [1],
    "loggedin": [4],
    "culturecode": [1],
    "polling": [1],
    "systemname": [1],
    "messages": [32],
    "lastReadDate": [32],
    "messagesRead": [32],
    "setMessagesRead": [64],
    "hasNewMessages": [64],
    "hasNoMessages": [64],
    "refreshMessages": [64]
  }, [[10, "messagesRead", "handleMessagesRead"], [6, "navbar_UpdateFdgwToken", "updateFgToken"]], {
    "culturecode": ["updateCulture"],
    "systemname": ["updateSystemName"]
  }]]], ["scope-navbar-banner", [[1, "scope-navbar-banner", {
    "closeable": [4],
    "storage": [1],
    "type": [1],
    "heading": [1],
    "sticky": [4],
    "visible": [32]
  }]]], ["scope-popup", [[0, "scope-popup", {
    "attachedTo": [513, "popup-attached-to"],
    "endDate": [520, "popup-enddate"],
    "hide": [516, "popup-hide"],
    "popupId": [513, "popup-id"],
    "delay": [514, "popup-delay"],
    "placement": [513, "popup-placement"],
    "overlay": [4, "popup-overlay"],
    "headline": [8, "popup-headline"],
    "body": [8, "popup-body"],
    "bodyextra": [8, "popup-body-extra"],
    "button": [8, "popup-button-text"],
    "popUpClasses": [8, "popup-classes"],
    "keyEsc": [4, "popup-esc"],
    "tour": [32],
    "HTMLobj": [32],
    "hidePopup": [64],
    "addHTMLobj": [64]
  }, [[10, "scopePopupHideEv", "listenerHidePopup"], [10, "scopePopupShowEv", "listenerShowPopup"]], {
    "hide": ["watcherHide"]
  }]]], ["sss-navbar-tag", [[4, "sss-navbar-tag", {
    "left": [4]
  }]]], ["scope-navbar-language-mobile_19", [[1, "sss-navbar-side-menu", {
    "resourcesUrl": [513, "resources-url"],
    "defaultExpanded": [516, "expanded"],
    "defaultCollapsible": [516, "collapsible"],
    "culture": [513]
  }, [[4, "mobileMenuOpen", "handleMobileMenuOpen"], [4, "mobileMenuClosed", "handleMobileMenuClosed"]], {
    "culture": ["objectDataWatcher"]
  }], [0, "sss-navbar-header-navigation"], [0, "scope-theme"], [4, "scp-cookie-footer", {
    "culturecode": [1],
    "hideCookieButton": [4, "hide-cookie-button"]
  }, [[18, "cookiesClosePopupEvent", "closePopupHandler"]]], [4, "scp-cookie-settings", {
    "culturecode": [1],
    "privacyurl": [1],
    "cookiesConsentFromProp": [16],
    "show": [32],
    "cookiePreferences": [32]
  }, [[18, "cookiesShowPopup", "showPopupHandler"], [18, "cookiesUpdateConsentFromApp", "updateConsentFromAppHandler"], [18, "acceptAll", "acceptAllHandler"]], {
    "culturecode": ["objectDataWatcher"],
    "cookiesConsentFromProp": ["cookiesConsentFromPropWatcher"]
  }], [1, "sss-navbar-account", {
    "userData": [8, "user-data"],
    "userImage": [1, "user-image"],
    "userAdminLink": [1, "user-admin-link"],
    "sametab": [4],
    "hideMyscaniaProfile": [4, "hide-myscania-profile"]
  }, [[2, "click", "handleClick"], [3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"], [10, "navbar_UpdateUser", "updateUser"], [10, "update_account", "updateUser"], [10, "navbar_UpdateImage", "updateImage"]], {
    "userData": ["userDataWatcher"],
    "userAdminLink": ["userAdminLinkWatcher"],
    "sametab": ["sameTabWatcher"]
  }], [1, "sss-navbar-app-launcher", {
    "loginUrl": [1, "login-url"],
    "active": [1],
    "services": [32],
    "isLoggedIn": [32],
    "culture": [32]
  }, [[3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"], [2, "click", "handleClick"], [6, "navbar_UpdateToken", "updateToken"], [6, "navbarHeaderCultureChanged", "updateApps"], [6, "navbarLanguageSelected", "updateApps"]]], [4, "sss-navbar-application-brand"], [0, "sss-navbar-beta"], [0, "sss-navbar-brand"], [4, "sss-navbar-header", {
    "resourcesUrl": [1, "resources-url"],
    "environment": [1],
    "culture": [513],
    "ciamprofile": [4]
  }, [[6, "navbarLanguageSelected", "updateUser"], [10, "navbar_UpdateLanguage", "updateUser"], [6, "navbar_UpdateToken", "updateToken"], [6, "navbar_RefreshToken", "refreshToken"], [6, "navbar_SetClientName", "setClientName"]], {
    "culture": ["objectDataWatcher"]
  }], [1, "sss-navbar-language", {
    "currentLanguage": [32],
    "searchTerm": [32]
  }, [[2, "click", "handleClick"], [3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"]]], [4, "sss-navbar-menu-item", {
    "icon": [1],
    "label": [1]
  }, [[3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"], [2, "click", "handleClick"]]], [4, "sss-navbar-menu-item-dropdown", {
    "icon": [1],
    "label": [1],
    "flyOut": [32],
    "expanded": [32],
    "haExternalIcon": [32]
  }, [[8, "closeMenuItemDropdown", "closeDropdown"], [2, "click", "handleClick"]]], [0, "sss-navbar-tour-header", {
    "environment": [1],
    "showReset": [4, "show-reset"]
  }, [[2, "click", "handleClick"], [3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"], [10, "navbar_reloadTour", "reloadTour"], [6, "navbarHeaderCultureChanged", "updateTour"]]], [0, "sss-navbar-header-link", {
    "link": [1],
    "label": [1],
    "tooltip": [1],
    "newtab": [4]
  }, [[3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"], [2, "click", "handleNavigation"], [2, "keydown", "handleNavigation"]]], [0, "sss-navbar-mobile-global-menu"], [1, "scope-navbar-language-mobile", {
    "currentLanguage": [32],
    "searchTerm": [32]
  }, [[4, "mobileMenuClosed", "handleMobileMenuClosed"]]], [0, "sss-navbar-help", {
    "section": [1],
    "helpLink": [1, "help-link"]
  }, [[3, "mouseover", "handleMouseOver"], [3, "mouseout", "handleMouseLeave"], [2, "click", "handleClick"], [2, "keydown", "handleClick"]]]]]], options);
};
export { defineCustomElements };