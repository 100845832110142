import { KeycloakService, KeycloakEvent } from 'keycloak-angular';

import { environment } from '@environments/environment';
import { AuthSsoService } from '../services/auth/auth-sso.service';
import { setKeycloakCost } from '@shared/utils/angular-helpers';

export function initializeKeycloak(
  keycloakService: KeycloakService,
  authSsoService: AuthSsoService,
): () => Promise<any> {
  const t0 = performance.now();
  keycloakService.keycloakEvents$.subscribe((event: KeycloakEvent) => {
    // TODO FIX MANUAL SUBSCRIPTION
    // FIX EVERY MANUAL SUBSCRIPTION LEAKS ! ! !
    authSsoService.handleKeycloakEvent(event);
  });
  return (): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      try {
        await keycloakService.init({
          config: {
            url: environment.keycloak.issuer,
            realm: environment.keycloak.realm,
            clientId: environment.keycloak.clientId,
          },
          bearerExcludedUrls: ['/assets'],
          initOptions: {
            enableLogging: true,
            silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
            onLoad: 'check-sso',
            pkceMethod: 'S256',
            silentCheckSsoFallback: false,
          },
        });
        window.keycloak = keycloakService;
        console.log('initializing charging services app');
        const t1 = performance.now();
        setKeycloakCost(t1 - t0);
        resolve(true);
      } catch (error) {
        console.log('keycloak init error: ', error);
        // Todo: Redirect to unauthorize page
        reject(error);
      }
    });
  };
}
