<div class="navbar-wrapper" data-dd-privacy="mask">
  @let langCode = languageCode();
  <sss-navbar-header environment="{{ environmentName }}" [culture]="langCode">
    @if (environmentName !== 'prod') {
      <sss-navbar-tag left="true">{{ environmentName.toUpperCase() }}</sss-navbar-tag>
    }
    <sss-navbar-application-brand class="sss-navbar-separator">
      <span>{{ 'ChS_ChargingServices' | translate }}</span>
    </sss-navbar-application-brand>

    <div class="sss-navbar-menu-divider"></div>

    <sss-navbar-help environment="{{ environmentName }}" section="charging-services"></sss-navbar-help>

    @if (isLoggedIn()) {
      <sss-navbar-tour-header *ngIf="langCode" show-reset="true"></sss-navbar-tour-header>

      <navbar-header-notifications
        label="{{ 'Aviseringar' | translate }}"
        heading="{{ 'Aviseringar' | translate }}"
        tabindex="0"
      >
        <notifications-container
          culturecode="{{ langCode }}"
          environment="{{ environmentName }}"
          loggedin="true"
          systemname="ChargingServices"
        ></notifications-container>
      </navbar-header-notifications>
    }
    <sss-navbar-app-launcher></sss-navbar-app-launcher>
    <sss-navbar-account data-dd-privacy="mask">
      <li class="sss-navbar__list-group-item sss-navbar__dropdown-item" (click)="goToPrivacyPage()">
        <span>{{ 'Sekretesspolicy' | translate }}</span>
      </li>
    </sss-navbar-account>
    <sss-navbar-brand></sss-navbar-brand>
  </sss-navbar-header>
</div>
