import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable } from 'rxjs';
import { DepartureSchedulingApi } from '@shared/services/departure-scheduling/departure-scheduling.api';
import { PermissionsService } from '../services/auth/permissions.service';

@Injectable({
  providedIn: 'root',
})
export class DepartureSchedulingGuard {
  constructor(
    protected readonly router: Router,
    private departureScheduling: DepartureSchedulingApi,
    private permissionsService: PermissionsService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | any {
    // checking if we have equipment with DS
    if (this.departureScheduling.equipmentList()) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
