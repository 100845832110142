import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, skip } from 'rxjs';
import { UserService } from '../services/auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class OnboardingGuard {
  private userService = inject(UserService);
  private router = inject(Router);

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requiredUserPermissions = route.data['requiredUserPermissions'];
    const redirectUrl = route.data['redirectURL'];
    const onboardingPageAccessibleToOnboardedCustomer: boolean =
      route.data['onboardingPageAccessibleToOnboardedCustomer'];
    const currentURL = state.url;

    return this.userService.isCustomerOnboarded$.pipe(
      map((onboarded) => {
        if (onboarded && onboardingPageAccessibleToOnboardedCustomer == false) {
          //CUSTOMER ONBOARDED ALREADY REDIRECT CHARGING MANAGEMENT
          console.log('CUSTOMER ALREADY ONBOARDED, REDIRECTING TO CHARGING MANAGEMENT URL', redirectUrl);
          this.router.navigate([redirectUrl]);
          return false;
        }

        if (onboarded && requiredUserPermissions) {
          console.log('%cONBOARDED AND HAS ACCESS TO CHARGING MANAGEMENT!', 'background: green; font-weight: bold;');
          return true;
        }

        if (onboarded == false && currentURL != '/onboarding') {
          // FORCE NAVIGATE TO ONBOARDING URL
          console.log('%c CUSTOMER NOT ONBOARDED, REDIRECTING TO ONBOARDING URL', 'background: red;');
          this.router.navigate(['/onboarding']);
          return false;
        }

        if (onboarded == false && currentURL == '/onboarding') {
          // ALLOW ACCESS TO ONBOARDING URL
          console.log('%c CUSTOMER NOT ONBOARDED, ONBOARDING FLOW STARTED', 'background: blue;');
          return true;
        }

        return false;
      }),
    );
  }
}
